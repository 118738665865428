import React, { useEffect, useState } from "react";
import { Alert, Box, IconButton } from "@mui/material";
import MUITable from "./MUITable";
import { GridColDef } from "@mui/x-data-grid";
import "./Styles.css";
import Chatbot from "../components/ChatBot/ChatBot";
import {
  getKnowledgeAssetsApi,
  previewCobol,
  previewSas,
} from "../services/BackendServices";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { HTMLComponent } from "../components/HTMLComponent";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux/Hook";

interface asset {
  asset_name: string;
  createdOn: string;
  type: string;
}

const initialValue = {
  asset_name: "",
  createdOn: "",
  type: "",
};

interface State extends SnackbarOrigin {
  open: boolean;
}

const KnowledgeAssets: React.FC = () => {
  const notification = useAppSelector((state) => state.NotificationAlert);

  const dispatch = useDispatch();
  const [openChat, setOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState<asset[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<asset>(initialValue);
  useEffect(() => {
    getKnowLedgeAssets();
  }, []);

  const columns: GridColDef<asset>[] = [
    { field: "id", headerName: "ID", minWidth: 110, editable: true },

    {
      field: "asset_name",
      headerName: "Asset Name",
      minWidth: 400,
      editable: true,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 160,
      editable: true,
      flex: 1,
    },
    {
      field: "creation_date",
      headerName: "Creation Date",
      type: "string",
      minWidth: 250,
      editable: true,
      flex: 1,
    },
    {
      field: "preview",
      headerName: "Preview",
      type: "string",
      minWidth: 130,
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => handlePreview(params.row)}
            aria-label="edit"
          >
            <RemoveRedEyeIcon />
          </IconButton>
        </div>
      ),
    },

    {
      field: "interact",
      headerName: "Interact",
      type: "string",
      minWidth: 130,
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <div>
          <IconButton
            color="primary"
            onClick={() => handleInteract(params.row)}
            aria-label="edit"
          >
            <SmartToyIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleInteract = (params: any) => {
    setSelectedAsset(params);
    setOpenChat(!openChat);
  };

  const handlePreview = (params: any) => {
    setSelectedAsset(params);
    if (params?.type == "cobal") {
      handlePreviewCobal(params);
    } else if (params.type === "sas") {
      handlePreviewSas(params);
    } else {
      handlePreviewCobal(params);
    }
  };

  const handlePreviewCobal = async (params: any) => {
    const response: any = await previewCobol(params?.asset_name);
    HTMLComponent(selectedAsset?.asset_name, response?.data);
  };

  const handlePreviewSas = async (params: any) => {
    const response: any = await previewSas(params?.asset_name);
    HTMLComponent(selectedAsset?.asset_name, response?.data);
  };

  const getKnowLedgeAssets = async () => {
    setLoading(true);
    const response: any = await getKnowledgeAssetsApi();
    if (response) {
      const updatedItems = response?.data?.mongo_knowledge_assets
        .filter((item: any) => item.type !== "markdown")
        .map((item: any, index: any) => ({
          ...item, // Spread existing object properties
          id: index + 1, // Add a unique id (or use any other logic)
        }));

      setAssets(updatedItems);
    } else {
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "error",
          message: response?.data?.message ?? response?.message,
          duration: 3000,
        },
      });
    }
  };

  const closeChatBot = () => {
    setSelectedAsset(initialValue);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={2}>
        <div className="knowledge-table-container">
          <div>Knowledge Assets</div>
          <div>
            <MUITable
              columns={columns}
              rows={assets}
              pagination={false}
              tableHeight={"80vh"}
            />
          </div>
        </div>
      </Box>

      <div className="knowledgeAsset-chatbot-container">
        <Chatbot
          closeChatBot={closeChatBot}
          open={openChat}
          selectedAsset={selectedAsset?.asset_name}
        />
      </div>
    </>
  );
};

export default KnowledgeAssets;
