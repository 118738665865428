import axios from "axios";
import {
  GET_KNOWLEDGE_ASSETS,
  INTERACT_CHAT_ENDPOINT,
  INTERACT_CHAT_KNOWLEDGE_ENDPOINT,
  PREVIEW_COBOL,
  PREVIEW_SAS,
  UPLOAD_ENDPOINT,
} from "../constants/endpoints";

export const uploadFile = async (
  file: File,
  operation: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("operation", operation);

    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${UPLOAD_ENDPOINT}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    return {
      success: true,
      data: response?.data?.data,
      message: response.data.message,
    };
  } catch (error: any) {
    return {
      success: false,
      message:
        error?.response?.data?.message || error.message || "An error occurred",
    };
  }
};
export const interactVectorDb = async (
  userInput: string
): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${INTERACT_CHAT_ENDPOINT}`,
      {
        user_input: userInput,
      },
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data } = response;
    if (data.status === "success") {
      return {
        success: true,
        data: data.response,
        message: data.message,
      };
    }

    return { success: false, message: data.message };
  } catch (error: any) {
    return { success: false, message: error?.message || "An error occurred" };
  }
};

export const interactKnowledgeAsset = async (body: {
  user_input: string;
  selected_asset: string;
}): Promise<{ success: boolean; data?: any; message?: string }> => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_GATEWAY_URL}${INTERACT_CHAT_KNOWLEDGE_ENDPOINT}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data } = response;

    // return { success: true, data: "data.data" };
    if (response.data?.status === "success") {
      return {
        success: true,
        data: response?.data,
        message: data.message,
      };
    }

    return { success: false, message: data.message };
  } catch (error: any) {
    return { success: false, message: error?.message || "An error occurred" };
  }
};

export const previewSas = async (fileName: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${PREVIEW_SAS}${fileName}`,
      {
        responseType: "blob", // Important to handle binary data
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );

    const { data, status } = response;
    if (status === 200) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: true,
        data: data?.message,
      };
    }
  } catch (error) {
    console.error("Error opening file: ", error);
  }
};

export const previewCobol = async (fileName: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${PREVIEW_COBOL}${fileName}`,
      {
        // responseType: "blob", // Important to handle binary data
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );
    const { data, status } = response;
    if (status === 200) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: true,
        data: data?.message,
      };
    }
  } catch (error) {
    console.error("Error opening file: ", error);
  }
};

export const getKnowledgeAssetsApi = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${GET_KNOWLEDGE_ASSETS}`,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        },
      }
    );
    const { data } = response;
    if (data.status === "success") {
      return {
        success: true,
        data: data,
        message: data?.message ?? "",
      };
    }

    return { success: false, message: data.message };
  } catch (error: any) {
    return { success: false, message: error?.message || "An error occurred" };
  }
};
