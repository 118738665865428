import React, { useState } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { ETL } from "./Constants";
import "./Styles.css";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { uploadFile } from "../services/BackendServices";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux/Hook";

const CreateKnowledge: React.FC = () => {
  const notification = useAppSelector((state) => state.NotificationAlert);
  const dispatch = useDispatch();
  const [etlOption, setEtlOption] = useState<string | null>(null);
  const [folderName, setFolderName] = useState<string | null>(null);
  const [fileInput, setFileInput] = useState<File | null>(null);
  const [zipFile, setZipfile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const handleETLChange = (
    event: React.SyntheticEvent,
    value: { label: string; value: string } | null
  ) => {
    setEtlOption(value ? value.value : null);
    setFileInput(null);
    setZipfile(null);
  };

  const handleFolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setZipfile(file);
      handleFileUpload(file, "process_sas");
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const allowedExtensions = ["*"];
      const fileExtension = file?.name
        .slice(file.name.lastIndexOf("."))
        .toLowerCase();
      setFileInput(file);
      handleFileUpload(file, "process_cobol");
    }
  };

  const handleFileUpload = async (file: File, operation: string) => {
    setLoading(true);
    const response: any = await uploadFile(file, operation);
    if (response) {
      setZipfile(null);
      setFileInput(null);
      setLoading(false);
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "success",
          message: response.message,
          duration: 3000,
        },
      });
    } else {
      dispatch({
        type: "SEND_ALERT",
        data: {
          ...notification,
          enable: true,
          type: "success",
          message:
            response?.data?.message ??
            response?.message ??
            "failed to upload message",
          duration: 3000,
        },
      });
      setLoading(true);
    }
  };

  return (
    <Box className="test-container">
      <span>Select Source Type</span>
      <Autocomplete
        size="small"
        options={ETL}
        getOptionLabel={(option) => option.label}
        onChange={handleETLChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            className="test-autocomplete"
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />

      {etlOption === "sas" && !loading && (
        <Button
          variant="contained"
          component="label"
          className="test-button"
          endIcon={<InsertDriveFileIcon />}
        >
          Select File
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleFolderChange}
            accept="*"
          />
        </Button>
      )}

      {etlOption === "cobol" && !loading && (
        <Button
          variant="contained"
          component="label"
          className="test-button"
          endIcon={<InsertDriveFileIcon />}
        >
          Select File
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept="*"
          />
        </Button>
      )}

      {zipFile && (
        <Typography variant="body2" className="test-file-label">
          {zipFile?.name}
        </Typography>
      )}

      {fileInput && (
        <Typography variant="body2" className="test-file-label">
          {fileInput?.name}
        </Typography>
      )}
      <Box mt={3}>{loading && <CircularProgress />}</Box>
    </Box>
  );
};

export default CreateKnowledge;
