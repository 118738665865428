import React, { FC } from "react";
import CreateKnowledge from "./pages/CreateKnowledge";
import Dashboard from "./pages/Dashboard";
import Interact from "./pages/Interact";
import KnowledgeAssets from "./pages/KnowledgeAssets";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PeopleIcon from "@mui/icons-material/People";

interface Route {
  key: string;
  title: string;
  path: string;
  enabled: boolean;
  component: FC<{}>;
  index: number;
  icon: FC<{}>;
}

export const routes: Array<Route> = [
  {
    key: "home",
    title: "Home",
    path: `${process.env.PUBLIC_URL}/home`,
    enabled: true,
    component: Dashboard,
    index: 0,
    icon: HomeIcon,
  },

  {
    key: "createKnowledgeAssets",
    title: "Create Knowledge Assets",
    path: `${process.env.PUBLIC_URL}/createKnowledgeAssets`,
    enabled: true,
    component: CreateKnowledge,
    index: 0,
    icon: MenuBookIcon,
  },
  {
    key: "exploreknowledgeAssets",
    title: "Explore knowledge Base",
    path: `${process.env.PUBLIC_URL}/exploreknowledgeAssets`,
    enabled: true,
    component: KnowledgeAssets,
    index: 0,
    icon: SearchIcon,
  },
  {
    key: "interact",
    title: "Ask Awgment",
    path: `${process.env.PUBLIC_URL}/askawgment`,
    enabled: true,
    component: Interact,
    index: 0,
    icon: PeopleIcon,
  },

  /* Add additional routes in Routes.ts as required following the above format: */
];
